/*=========================================================================================
  File Name: moduleCourseClassActions.js
  Description: CourseClass Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import axios from "@/axios.js";
export default {
  addItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("vehicles/AddNewVehiclesAxle", item)
        .then(response => {
          commit("ADD_VehiclesAxles", Object.assign(item, { ID: response.data.ID }));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchAllVehiclesAxlesList({ commit }) {
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/GetAllVehiclesAxles")
        .then(response => {
          if (response.status == 200) {
            commit("SET_VehiclesAxles", response.data);
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  fetchVehiclesAxlesByID({ commit }) {
    var Jsonvalue = {};
    Jsonvalue.CountryCode = "All";
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/GetVehiclesAxlesId", Jsonvalue)
        .then(response => {
          if (response.status == 200) {
            commit("SET_VehiclesAxles", response.data);
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  updateItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/UpdateVehiclesAxle", item)
        .then(response => {
          debugger
          commit("UPDATE_VehiclesAxles", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  DeleteVehiclesAxles({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/DeleteVehiclesAxle", item)
        .then(response => {
          debugger
          commit("UPDATE_VehiclesAxles", item);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetCVehiclesAxlesById(context, VehiclesAxles) {
    if (VehiclesAxles.Id == undefined) {
      VehiclesAxles.Id = "";
    }   
    return new Promise((resolve, reject) => {
      axios
        .post("/vehicles/Get", country)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
 
};
