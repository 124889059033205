/*=========================================================================================
  File Name: modulecourseClassMutations.js
  Description: courseClass Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_VehiclesAxles(state, item) {
    state.VehiclesAxles.unshift(item);
  },

  SET_VehiclesAxles(state, VehiclesAxles) {
    state.VehiclesAxles = VehiclesAxles;
  },

  SET_ALLVehiclesAxles(state, allVehiclesAxles) {
    state.AllVehiclesAxles = allVehiclesAxles;
  },

  UPDATE_VehiclesAxles(state, item) {
    const Index = state.VehiclesAxles.findIndex(p => p.Id == item.Id);
    Object.assign(state.VehiclesAxles[Index], item);
  }
  
};
